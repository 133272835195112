<template>
  <Modal id="share-modal" no-padding hide-footer>
    <Accordion v-model="tabs" class="share-modal-accordion">
      <AccordionItem>
        <template #title>
          <div class="flex items-center">
            <span class="mr-5 grid h-10 w-10 place-items-center rounded bg-blue-100 text-xl text-blue-500">
              <Icon icon="facebook" prefix="fab" />
            </span>
            <p class="font-medium text-heading">Share on Facebook</p>
          </div>
        </template>

        <div class="flex items-center space-x-5 rounded bg-primary-50 p-6 text-sm text-primary-500">
          <Icon icon="robot" prefix="fal" class="text-3xl" />
          <p>Pretty cool, right? Approach Guides AI is crafting a unique, high-impact Facebook post. Use as it is or give it your personal touch.</p>
        </div>

        <ChatAi
          v-model="facebookPost"
          endpoint="facebook-post"
          :params="{ hashid }"
          :partner-name="partnerName"
          :initial-message="socialShareText"
          @pending="onFacebookPostPending"
          class="my-8 shadow"
          :replace="{ '[link]': landingPageUrl }"
        />

        <div class="space-y-4 sm:flex sm:items-center sm:justify-between sm:space-x-6 sm:space-y-0">
          <p class="text-sm text-supplement">
            <span class="sm:hidden">Click to copy text then paste into Facebook to edit and share.</span>
            <span class="hidden sm:block">Click to copy the text above and open Facebook automatically. Then simply paste, edit and share.</span>
          </p>
          <div class="shrink-0">
            <Button append-icon="share" append-icon-prefix="fas" color="pink" @click="copyAndShareFacebookPost" :disabled="facebookPostPending">
              <span>Copy text &amp; share post</span>
            </Button>
          </div>
        </div>
      </AccordionItem>

      <AccordionItem>
        <template #title>
          <div class="flex items-center">
            <span class="mr-5 grid h-10 w-10 place-items-center rounded bg-[#dcedfd] text-xl text-[#0a66c2]">
              <Icon icon="linkedin" prefix="fab" />
            </span>
            <p class="font-medium text-heading">Share on LinkedIn</p>
          </div>
        </template>

        <div class="flex items-center space-x-5 rounded bg-primary-50 p-6 text-sm text-primary-500">
          <Icon icon="robot" prefix="fal" class="text-3xl" />
          <p>Pretty cool, right? Approach Guides AI is crafting a unique, high-impact LinkedIn post. Use as it is or give it your personal touch.</p>
        </div>

        <ChatAi
          v-model="linkedinPost"
          endpoint="linkedin-post"
          :params="{ hashid }"
          :partner-name="partnerName"
          :initial-message="socialShareText"
          @pending="onLinkedinPostPending"
          class="my-8 shadow"
          :replace="{ '[link]': landingPageUrl }"
        />

        <div class="space-y-4 sm:flex sm:items-center sm:justify-between sm:space-x-6 sm:space-y-0">
          <p class="text-sm text-supplement">
            <span class="sm:hidden">Click to copy text then paste into LinkedIn to edit and share.</span>
            <span class="hidden sm:block">Click to copy the text above and open LinkedIn automatically. Then simply paste, edit and share.</span>
          </p>
          <div class="shrink-0">
            <Button append-icon="share" append-icon-prefix="fas" color="pink" @click="copyAndShareLinkedinPost" :disabled="linkedinPostPending">
              <span>Copy text &amp; share post</span>
            </Button>
          </div>
        </div>
      </AccordionItem>

      <AccordionItem>
        <template #title>
          <div class="flex items-center">
            <span class="mr-5 grid h-10 w-10 place-items-center rounded bg-[#b1f1c9] text-xl text-[#1b994a]">
              <Icon icon="whatsapp" prefix="fab" />
            </span>
            <p class="font-medium text-heading">Share on WhatsApp</p>
          </div>
        </template>

        <div>
          <Button as="a" :href="`https://wa.me/?text=${landingPageUrl}`" target="_blank" append-icon="share" append-icon-prefix="fas" color="pink"
            >Share via WhatsApp</Button
          >
        </div>
      </AccordionItem>

      <AccordionItem>
        <template #title>
          <div class="flex items-center">
            <span class="mr-5 grid h-10 w-10 place-items-center rounded bg-purple-100 text-xl text-purple-500">
              <Icon icon="envelope" />
            </span>
            <p class="font-medium text-heading">Share by Email</p>
          </div>
        </template>

        <div class="flex items-center space-x-5 rounded bg-primary-50 p-6 text-sm text-primary-500">
          <Icon icon="robot" prefix="fal" class="text-3xl" />
          <p>
            Say goodbye to writer's block! Approach Guides AI is crafting a customized, original email tailored to your clients. Use it as is or give it your
            personal touch.
          </p>
        </div>

        <ChatAi
          v-model="emailCopy"
          endpoint="personal-email"
          :params="{ hashid }"
          @pending="onEmailPending"
          class="my-8 shadow"
          :replace="{ '[link]': landingPageUrl }"
        />

        <div class="flex items-center justify-between space-x-6">
          <p class="max-w-xs text-sm text-supplement">
            <span class="sm:hidden">Paste into the body of a new email.</span>
            <span class="hidden sm:block">To send to clients, simply paste into the body of a new email in your favorite email program.</span>
          </p>
          <div class="shrink-0">
            <Button :prepend-icon="emailCopied ? 'check' : 'copy'" prepend-icon-prefix="fas" color="pink" @click="copyDecodedEmail" :disabled="emailPending">
              {{ emailCopied ? "Copied!" : "Copy email" }}
            </Button>
          </div>
        </div>
      </AccordionItem>

      <AccordionItem>
        <template #title>
          <div class="flex items-center">
            <span class="mr-5 grid h-10 w-10 place-items-center rounded bg-teal-100 text-xl text-teal-500">
              <Icon icon="qrcode" />
            </span>
            <p class="font-medium text-heading">Get QR Code</p>
          </div>
        </template>

        <div class="md:flex">
          <div class="relative grid h-[160px] w-[160px] flex-shrink-0 place-items-center bg-gray-50">
            <Icon icon="spinner-third" prefix="fad" spin class="text-2xl" />
            <img :src="`${backendUrl}/shared-collection-personalisations/${hashid}/qr`" height="160" width="160" class="absolute inset-0" />
          </div>
          <div class="space-y-6 md:ml-10">
            <p class="mt-6 md:mt-0">Download the code or screenshot it for even faster sharing.</p>
            <p>
              <Button as="a" :href="`${`${backendUrl}/shared-collection-personalisations/${hashid}/qr`}?download`" prepend-icon="cloud-arrow-down" color="brand"
                >Download QR code</Button
              >
            </p>
          </div>
        </div>
      </AccordionItem>

      <AccordionItem>
        <template #title>
          <div class="flex items-center">
            <span class="mr-5 grid h-10 w-10 place-items-center rounded bg-amber-100 text-xl text-amber-500">
              <Icon icon="link" />
            </span>
            <p class="font-medium text-heading">Get Link</p>
          </div>
        </template>

        <div class="mx-auto max-w-lg space-y-4">
          <p class="text-center font-semibold">The possibilities are limitless...</p>
          <input type="text" :value="landingPageUrl" class="w-full rounded-md border px-4 py-3" readonly />
          <UseClipboard v-slot="{ copy, copied }" :source="landingPageUrl" :copied-during="2000">
            <Button :prepend-icon="copied ? 'check' : 'copy'" prepend-icon-prefix="fas" color="pink" full-width @click="copy()">
              <span v-if="copied">Copied!</span>
              <span v-else>Copy link to your content experience</span>
            </Button>
          </UseClipboard>
        </div>
      </AccordionItem>
    </Accordion>
  </Modal>
</template>

<script setup lang="ts">
import { UseClipboard } from "@vueuse/components";
import { useClipboard } from "@vueuse/core";

export interface ShareModalProps {
  landingPageUrl: string;
  partnerName: string;
  socialShareText: string | null;
  openTab?: number;
}

const props = withDefaults(defineProps<ShareModalProps>(), {
  openTab: 1,
});

const hashid = getVersionHashidFromUrl();
const { backendUrl } = useRuntimeConfig().public;

// Tabs
const tabs = ref(props.openTab);

// Facebook post
const facebookPost = ref(null);
const { copy: copyFacebookPost } = useClipboard({ source: facebookPost });

const facebookPostPending = ref(true);
const onFacebookPostPending = (value: boolean) => (facebookPostPending.value = value);

const copyAndShareFacebookPost = async () => {
  await copyFacebookPost();
  window.open(`https://www.facebook.com/share.php?u=${props.landingPageUrl}`, "_blank");
};

// LinkedIn post
const linkedinPost = ref(null);
const { copy: copyLinkedinPost } = useClipboard({ source: linkedinPost });

const linkedinPostPending = ref(true);
const onLinkedinPostPending = (value: boolean) => (linkedinPostPending.value = value);

const copyAndShareLinkedinPost = async () => {
  await copyLinkedinPost();
  window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${props.landingPageUrl}`, "_blank");
};

// Email
const emailCopy = ref(null);
const emailPending = ref(true);
const emailCopied = ref(false);
const onEmailPending = (value: boolean) => (emailPending.value = value);

const copyDecodedEmail = () => {
  const { copy } = useClipboard();
  copy(decodeURIComponent(emailCopy.value));
  emailCopied.value = true;
  setTimeout(() => (emailCopied.value = false), 2000);
};
</script>

<style lang="postcss">
#share-modal .modal__close {
  @apply -mr-3 -mt-3;
}

.share-modal-accordion {
  .accordion-item {
    .accordion-item__header {
      @apply p-6 pr-8;
    }
  }
}
</style>
